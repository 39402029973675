.contact-container {
  font-family: "Poppins", sans-serif;

  .contact-content {
    margin: 10vw 0px;
    padding: 0px 3vw;

    .header {
      font-size: 18px;
      font-weight: 700;
    }

    p {
      font-size: 12px;
      font-weight: 700;

      span {
        color: black;
      }

      a {
        color: grey;
      }
    }

    .socials {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      span {
        display: flex;
        gap: 15px;
      }
    }
  }
}

.quote-subscribe-container {
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  margin-top: 10vw;
}

.quote-subscribe {
  width: 95%;
  background-color: #0066ff;
  border-radius: 10px 0px 0px 10px;

  .quote-content {
    .quote-desc {
      color: white;
      margin: 0px 25vw;
      text-align: center;
      line-height: 1em;
      font-size: 20px;
    }

    .quote-author {
      margin-bottom: 20px;
      font-family: "Freehand", cursive;
      font-size: 16px;
      color: white;
    }
  }
}

.review-header {
  font-size: 30vw;
  text-align: center;
  line-height: 0px;
  color: rgba(226, 232, 240, 0.8);
  margin-bottom: 5vw;
  font-family: sans-serif;
}

.subscribe-form-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 981px) {
  .quote-subscribe {
    width: 100%;
    border-radius: 0px 0px 0px 0px;

    .quote-content {
      .quote-desc {
        margin: 0px 10vw !important;
        font-size: 16px !important;
      }

      .quote-author {
        font-size: 12px !important;
      }
    }
  }
}

.subscribe-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-items: start;
  overflow: clip;

  .subscribe-cutout {
    font-size: 5.4vw;
    font-weight: 800;
    line-height: 1em;
    text-align: start;
    position: relative;
    margin: 2;
    margin-top: 1vw !important;
    color: black;
    font-family: "Mukta", sans-serif;
    opacity: 0;

    &.show-subscribe-cutout {
      animation: 2s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38)
        subscribeSlideDown;
      animation-delay: 0s;
    }
  }
}

@keyframes subscribeSlideDown {
  0% {
    opacity: 0;
    transform: translateX(23vw);
  }
  100% {
    opacity: 1;
    transform: translateX(2vw);
  }
}

@keyframes loopScroll {
  0% {
    left: 0px;
  }
  50% {
    left: -200px;
  }
  100% {
    left: 0px;
  }
}

.header-container {
  width: 100vw;
  height: 80px;
  position: fixed;
  top: -100px;
  transition: top 0.5s ease-in-out;
  transition-duration: 0.7s;
  font-family: "Poppins", sans-serif;
  z-index: 1000;
}

.header-content {
  width: 74%;
  height: 100%;
  display: flex;
  justify-items: flex-start;
  align-items: center;

  &.contact-content {
    font-size: x-small;
    color: white;
    overflow: scroll;
    white-space: nowrap;
    position: relative;

    .details-container {
      font-family: "Poppins", sans-serif;
      animation-name: loopScroll;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      display: flex;
      gap: 10px;
      position: absolute;

      &:hover {
        animation-play-state: paused;
      }

      div {
        display: flex;
        align-content: center;
        gap: 5px;

        a {
          color: white;
        }
      }
    }
  }

  img {
    width: 10px;
  }
}

.show-header {
  top: 0px !important;
}

.contact-bar {
  display: flex;
  justify-content: center;
  width: inherit;
  height: 35%;
  background-color: gray;
}

.nav-bar {
  display: flex;
  justify-content: center;
  width: inherit;
  height: 65%;
  background-color: white;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

  a {
    text-decoration: none;
  }

  li {
    list-style: none;
  }

  .nav-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  input[type="checkbox"] {
    display: none;
  }

  .hamburger {
    display: none;
    font-size: 24px;
    user-select: none;
  }

  .nav-links a {
    color: #000000;
  }

  .menu {
    display: flex;
    gap: 1em;
    font-size: 14px;
    font-weight: 500;
  }

  .menu li:hover {
    background-color: rgba(128, 128, 128, 0.3);
    border-radius: 5px;
    transition: 0.3s ease;
  }

  .menu li {
    padding: 5px 14px;

    &.active {
      background-color: rgba(13, 79, 160, 0.3);
      border-radius: 5px;
      transition: 0.3s ease;
    }
  }
}

.logo {
  img {
    width: 60px;
    height: 29px;
  }
}

@media (max-width: 981px) {
  .header-container {
    position: relative !important;
    top: 0px !important;
    transition: unset !important;
  }

  .nav-bar {
    .menu {
      display: none;
      position: absolute;
      background-color: #fff;
      right: 0;
      left: 0;
      font-size: 12px;
      text-align: center;
      padding: 16px 0;
    }

    .menu li:hover {
      display: inline-block;
      background-color: rgba(128, 128, 128, 0.3);
      transition: 0.3s ease;
    }

    .menu li + li {
      margin-top: 12px;
    }

    input[type="checkbox"]:checked ~ .menu {
      display: block;
    }

    .hamburger {
      display: block;
    }
  }
}

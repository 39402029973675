.card {
    width: 250px;
    height: 250px;
    background-color: transparent;
    perspective: 1000px;
    font-family: 'Poppins', sans-serif;
    z-index: 100;

    &:hover .card-inner{
        transform: rotateY(180deg);
    }
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 4px rgba(235,33,40,0.25);
    border-radius: 25px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
}

.text-back {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(13,79,160,.8);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;

    div {
        width: 85%;
        margin: auto;
    }

    .text-back-header {
        border-bottom: 1px solid rgba(235,33,40,255);
        padding-bottom: 7px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1em;
        font-family: 'Mukta', sans-serif;
    }

    .text-back-desc {
        font-size: 12px;
        font-weight: 400;
        margin-top: 2px;
        white-space: pre-line;
    }
}


.flip-card-front, .flip-card-back  {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    border: 1px rgba(13,79,160,255) solid;
    background-color: white;
    
    img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 25px;
    }

    div {
        background-color: rgba(0,0,0,255);
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 30%;
        border-radius: 0px 0px 25px 25px;
    }

    h2 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: white;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'Mukta', sans-serif;
    }
}

.flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
}
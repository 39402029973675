.HomeContainer {
    width: 100vw;
    background-image: url('../../assets/images/airport.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    display: grid;
    font-family: 'Poppins', sans-serif;
    // font-

    .Blur {
        width: 100%;
        background-image: linear-gradient(to right, rgba(0,0,0,255), rgba(0,0,0,255), rgba(0,0,0,0.9), rgba(13,79,160,0.6), rgba(235,33,40,0.3));
        display: grid;
        grid-template-columns: auto;

        .welcome-container {
            height: 100vh;
            display: grid;
            align-content: center;

            .welcome-content {
                width: 80%;
                max-width: 1080px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .mask-content {
            height: 27vw;
            width: 100%;
            display: flex;
            justify-items: start;
            overflow: hidden;
            
            .mask-image {
                // height: 20vw;
                font-size: 27vw;
                font-weight: 800;
                line-height: normal;
                margin: 0px;
                text-align: start;
                margin-left: -2% !important;
                margin-top: -2vw !important;
                // color: -webkit-linear-gradient(to right, rgba(13,79,160,0.6), rgba(235,33,40,0.3));
                background: linear-gradient( to right,  rgba(13,79,160,0.4), rgba(235,33,40,0.3), rgba(255 ,255, 255,0.6));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: 'Mukta', sans-serif;
                opacity: 0;

                &.show-mask-cutout {
                    animation: 2s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;
                    animation-delay: 0s;
                }
            }
        }
    }
}

@media (max-width: 981px) {
    .welcome-container {
        height: 65vh !important;
        justify-content: center;

        .welcome-content {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .mask-content {
        justify-items: center !important;
    }

    .contact-about {
        display: grid !important;
    }

    .welcome-desc {
        font-size: 2.5vw !important;
        text-align: center !important;
    }

    .welcome-desc-2 {
        font-size: 2vw !important;
    }

    .welcome-text {
        font-size: 6vw !important;
        text-align: center !important;
    }

    .welcome-logo {
        margin-left: 17vw !important;
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;                    
        transform: translateY(23vw);              
    }                
    100% {                    
        opacity: 1;                    
        transform: translateY(0);              
    }            
}

@media (max-width: 510px) {
    .welcome-text {
        font-size: 5vw !important;
    }

    .welcome-logo {
        margin-top: 2vh !important;
        margin-bottom: 2vh !important; 
    }

    .welcome-desc {
        font-size: 3vw !important;
    }
    
    .welcome-desc-2 {
        font-size: 2vw !important;
    }
}

.welcome-text {
    margin-left: 3vw;
    color: white;
    text-align: start;
    font-family: 'Freehand', cursive;
    font-weight: 700;
    font-size: 2.5vw;
    line-height: 1em;
}

.welcome-logo {
    margin-left: 3vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 40vw;
    height: 12vw;
    background-color: white;
    mask: url('../../assets/images/logo.png') center left / 60% 100% no-repeat;
}

.welcome-desc {
    font-weight: 600;
    font-size: 1.8vw;
    letter-spacing: .2vw;
    line-height: 1em;
    color: white;
    text-align: left;
}

.welcome-desc-2 {
    color: white;
    text-align: start;
    font-size: 1.2vw;
    font-weight: 600;
    letter-spacing: .4vw;
    line-height: .5em;

    span {
        color: #4ade80;

        &:hover {
            border-color: #22c55e;
            color: #22c55e;
        }
    }
}

.contact-about {
    display: flex;
    justify-items: start;
    gap: 20px;

    .more-info-container {
        margin-top: 2vw;
        display: flex;
        justify-content: left;
        z-index: 10;

        .more-info-button {
            width: 200px;
            height: 50px;
            background-color: transparent;
            border-width: 2px;
            border-color: white;
            border-radius: 5px;
            font-size: 16px;
            color: white;
            font-weight: 700;
        
            &:hover {
                box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
            }

            &:focus {
                box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
            }
            
        }
    }

    .contact-button-container {
        margin-top: 2vw;
        display: flex;
        justify-content: left;
        z-index: 10;
    
        .contact-button {
            width: 200px;
            height: 50px;
            background-color: rgba(13,79,160,255);
            border: 0px;
            border-radius: 5px;
            font-size: 16px;
            color: white;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
            }

            &:focus {
                box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
            }
        }
    }
}
